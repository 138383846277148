import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import CompanyLogoWhite from "../../assets/images/123tcs.logo.svg";
import { LanguageContext } from "../../contexts/Language";
import ChangeLanguage from "../atoms/ChangeLanguage";

function Header() {
  // Contexts
  const [language] = useContext(LanguageContext);

  // States
  const [isOpen, setIsOpen] = useState(false);

  // Constants
  const navigation = [
    { name: language.home, href: "#banner" },
    { name: language.about, href: "#about" },
    { name: language.services, href: "#services" },
    { name: language.integrations, href: "#intergrations" },
    { name: language.contact, href: "#contact" },
  ];

  // Render
  return (
    <div className="fixed top-0 left-0 z-10 w-full border-b md:border-none dark:bg-deepDarkBlue dark:text-white bg-secondary">
      <div className="container">
        <div className="flex justify-between items-center space-x-16 w-full">
          {/* LOGO */}
          <button type="button" onClick={() => window.location.reload()}>
            <img className="flex-shrink-0 my-auto h-14 cursor-pointer" src={CompanyLogoWhite} alt="123TCS Logo" />
            {/* <span className="hidden px-3 py-2 text-base font-bold text-white rounded-md md:inline-block md:text-lg">123TCS</span> */}
          </button>

          {/* NAVIGATION */}
          <div className="hidden w-3/5 md:block md:1/2">
            <div className="flex justify-between items-baseline w-full md:space-x-2 lg:space-x-8">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-300 hover:bg-gray-500 hover:text-white dark:text-white">
                  {item.name}
                </a>
              ))}
            </div>
          </div>

          {/* LANGUAGE */}
          <ChangeLanguage />

          {/* HAMBURGER MENU */}
          <div className="flex md:">
            <div className="md:hidden">
              <div className="flex items-center mx-4 h-full md:ml-6">
                <Button variant="contained" size="small" onClick={() => setIsOpen(!isOpen)} sx={{ minWidth: "0px", ml: "10px" }}>
                  <MenuIcon className={`dark:text-white ${"text-white"}`} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={isOpen ? "block px-2 pt-2 pb-3 space-y-1 md:hidden sm:px-3" : "hidden"}>
        {navigation.map((item) => (
          <a
            key={item.name}
            href={item.href}
            onClick={() => setIsOpen(!isOpen)}
            className="block px-3 py-2 text-lg font-bold text-white rounded-md hover:bg-gray-700 hover:text-white dark:text-white"
          >
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );
}

export default Header;
