import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import { Button, Chip, Grid, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { LanguageContext } from "../../contexts/Language";
import MeetingBooker from "../molecules/MeetingBooker";

function Banner() {
  const chipStyle = {
    padding: 1,
    background: "#f8fafc",
    "&:hover": { background: "#d4d4d8" },
  };
  const colorButton = "#477D9A";
  const colorButtonHover = "#C8E0F4";

  // Contexts
  const [language] = useContext(LanguageContext);
  const [open, setOpen] = useState(false);

  // Render
  return (
    <section className={`pt-10 text-center text-white min-h-[700px] dark:bg-darkBlue ${"bg-secondary"}`} id="banner">
      <div className="container block pt-40 pb-14 mx-auto mt-[60px]" style={{ maxWidth: "1200px", margin: "0 auto" }}>
        {/* TITLE */}
        <h1 className={`p-5 text-6xl font-bold text-center"text-white"`}>{language.banner.title}</h1>

        {/* CONTENT */}
        <div className={`p-5 text-lg text-center dark:text-textLightGrey text-tcsDarkBlue ${"text-white"}`}>{language.banner.content}</div>

        {/* CONTACT */}
        <Grid container spacing={2} justifyContent="center" direction="row">
          {/* CONTACT BUTTON */}
          <Grid item>
            <Button
              href="#contact"
              sx={{
                boxShadow: 5,
                minWidth: 250,
                height: 50,
                backgroundColor: colorButton,
                color: "white",
                "&:hover": { backgroundColor: colorButtonHover },
              }}
            >
              {language.contact}
            </Button>
          </Grid>

          {/* BOOK MEETING BUTTON */}
          <Grid item>
            <Button
              sx={{
                boxShadow: 5,
                minWidth: 250,
                height: 50,
                backgroundColor: colorButton,
                color: "white",
                "&:hover": { backgroundColor: colorButtonHover },
              }}
              onClick={() => setOpen(true)}
            >
              {language.bookAMeeting}
            </Button>
          </Grid>
        </Grid>

        <MeetingBooker open={open} onClose={() => setOpen(false)} />

        {/* CONTACT INFORMATION */}
        <Stack direction="row" spacing={2} justifyContent="center" textAlign="center" sx={{ marginTop: 6 }}>
          <Chip label="+31 (0)85 744 10 35" icon={<PhoneIcon />} component="a" href="tel:+31 (0)85 744 10 35" sx={chipStyle} clickable />

          <Chip label="info@123tcs.com" icon={<MailIcon />} component="a" href="mailto:info@123tcs.com" sx={chipStyle} clickable />

          <Chip
            label="Joris Dircksenstraat 18, Breukelen"
            icon={<PlaceIcon />}
            component="a"
            href="https://goo.gl/maps/ZbNR1Ce7akKdUgMbA"
            sx={chipStyle}
            clickable
          />
        </Stack>
      </div>
    </section>
  );
}

export default Banner;
