import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SendIcon from "@mui/icons-material/Send";
import { Alert, Box, Button, CircularProgress, Snackbar, TextField, Typography } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { LanguageContext } from "../../contexts/Language";

function ContactSection() {
  const textFieldStyle = { background: "#fff" };

  // Context
  const [language] = useContext(LanguageContext);
  const colorButton = "#477D9A";
  const colorButtonHover = "#C8E0F4";

  // Refs
  const formRef = useRef();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [inputContactError, setInputContactError] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [formValues, setFormValues] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  // Functions
  const verifyFormInput = (emailAddress, phoneNumber) => {
    if (phoneNumber.trim().length < 8 && emailAddress.trim().length === 0) {
      setInputContactError(true);
      setNotification({
        open: true,
        severity: "error",
        message: language.formContactError,
      });

      return false;
    }

    return true;
  };

  // Handlers
  const handleSubmit = async (event) => {
    event.preventDefault();
    setInputContactError(false);

    const inputVerification = verifyFormInput(event.target.emailAddress.value, event.target.phoneNumber.value);

    if (inputVerification) {
      setIsLoading(true);

      try {
        const requestContent = {
          name: event.target.name.value,
          emailAddress: event.target.emailAddress.value,
          phoneNumber: event.target.phoneNumber.value,
          subject: event.target.subject.value,
          message: event.target.message.value,
        };
        setFormValues(requestContent);
        const response = await fetch("https://cardbase.123tcs.io/v5/mail/website/contact", {
          method: "POST",
          body: JSON.stringify(requestContent),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        if (response.status !== 200) {
          throw Error("Server did not respond with 200.");
        }

        setIsSent(true);
      } catch (error) {
        setNotification({
          open: true,
          severity: "error",
          message: language.sentFailed,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification((notification) => ({
      ...notification,
      open: false,
    }));
  };

  // Render
  return (
    <>
      <Snackbar
        open={notification.open}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} variant="filled" severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>

      <section
        // className={`min-h-[500px] flex justify-center pt-20 dark:bg-deepDarkBlue dark:text-white text-tcsBlueDark ${
        //   props.dark ? "bg-secondary" : "bg-primary"
        // }`}
        className={`flex justify-center pt-10 text-white shadow min-h-[700px] ${"bg-secondary"}`}
        id="contact"
      >
        {!isSent && !isLoading && (
          <div className="container block items-center md:px-4 md:pt-10 md:pb-5">
            {/* TITLE */}
            <h1 className="p-3 text-3xl leading-10 text-center uppercase">{language.contactUs}</h1>

            {/* FORM */}
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="items-center mt-12 md:flex">
                {/* NAME */}
                <div className="flex flex-col w-full md:w-1/3">
                  <TextField
                    required
                    defaultValue={formValues.name}
                    label={language.name}
                    variant="filled"
                    type="text"
                    name="name"
                    sx={textFieldStyle}
                  />
                </div>

                {/* PHONE NUMBER */}
                <div className="flex flex-col mt-4 w-full md:w-1/3 md:ml-3 md:mt-0">
                  <TextField
                    label={language.phoneNumber}
                    defaultValue={formValues.phoneNumber}
                    error={inputContactError}
                    variant="filled"
                    type="tel"
                    name="phoneNumber"
                    sx={textFieldStyle}
                  />
                </div>

                {/* EMAIL ADDRESS */}
                <div className="flex flex-col mt-4 w-full md:w-1/3 md:ml-3 md:mt-0">
                  <TextField
                    label={language.emailAddress}
                    defaultValue={formValues.emailAddress}
                    error={inputContactError}
                    variant="filled"
                    type="email"
                    name="emailAddress"
                    sx={textFieldStyle}
                  />
                </div>
              </div>

              {/* SUBJECT */}
              <div className="items-center mt-8 md:flex">
                <div className="flex flex-col w-full">
                  <TextField
                    required
                    defaultValue={formValues.subject}
                    variant="filled"
                    label={language.subject}
                    type="text"
                    name="subject"
                    sx={textFieldStyle}
                  />
                </div>
              </div>

              {/* MESSAGE */}
              <div className="flex flex-col my-8 w-full">
                <TextField
                  required
                  multiline
                  rows={8}
                  defaultValue={formValues.message}
                  variant="filled"
                  label={language.message}
                  type="text"
                  name="message"
                  sx={textFieldStyle}
                />
              </div>

              {/* SUBMIT BUTTON */}
              <div className="flex justify-center items-center w-full">
                <Button
                  type="submit"
                  endIcon={<SendIcon />}
                  disabled={isLoading}
                  variant="contained"
                  className="m-6"
                  sx={{
                    paddingY: 2,
                    paddingX: 6,
                    backgroundColor: colorButton,
                    color: "white",
                    "&:hover": { backgroundColor: colorButtonHover },
                  }}
                >
                  {language.send}
                </Button>
              </div>
            </form>
          </div>
        )}

        {isLoading && (
          <Box sx={{ padding: 32, color: "grey.500", display: "flex" }}>
            <CircularProgress color="inherit" />
          </Box>
        )}

        {isSent && !isLoading && (
          <Box fontSize="128px" justifyContent="center" textAlign="center">
            <CheckCircleIcon color="success" fontSize="inherit" />
            <Typography
              fontSize="inherit"
              sx={{
                color: "success.main",
                fontWeight: "bold",
                paddingTop: "1px",
              }}
            >
              {language.sentSuccessfully}
            </Typography>
          </Box>
        )}
      </section>
    </>
  );
}

export default ContactSection;
