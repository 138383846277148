import React, { useContext } from "react";
import { importFiles } from "../../utils/utils";
import { LanguageContext } from "../../contexts/Language";
import Logos from "../molecules/Logos";
import FeelingzLogo from "../../assets/images/integrations/gifts/feelingz.svg";

function GiftLogos() {
  const [language] = useContext(LanguageContext);
  const images = importFiles(require.context("../../assets/images/integrations/gifts/", false, /\.(webp|gif|png|jpe?g|svg)$/));

  const logos = [
    {
      alt: "Makro Kerstpakketten",
      src: images["makro.svg"],
      href: "https://www.makrokerstpakketten.nl/",
    },
    {
      alt: "Feelingz",
      src: FeelingzLogo,
      href: "https://feelingz.nl/",
    },
    {
      alt: "Green Gift Box",
      src: images["green-gift-box.png"],
      href: "https://www.greengiftbox.nl/",
    },
    {
      alt: "Bongo",
      src: images["bongo.svg"],
      href: "https://www.bongo.nl/",
    },
    {
      alt: "Tintelingen",
      src: images["tintelingen.svg"],
      href: "https://www.tintelingen.nl/",
    },
    {
      alt: "Pluimen",
      src: images["pluimen.svg"],
      href: "https://www.pluimen.nl/",
    },
    {
      alt: "Gemaco",
      src: images["gemaco.jpg"],
      href: "https://blog.gemacoglobal.com/",
    },
    {
      alt: "More Than Gifts",
      src: images["more-than-gifts.webp"],
      href: "https://www.morethangifts.nl/",
    },
    {
      alt: "MijnEigenKadootje",
      src: images["mijn-eigen-kadootje.png"],
      href: "https://www.mijneigenkadootje.nl/",
    },
    {
      alt: "STERKADO",
      src: images["sterkado.svg"],
      href: "https://sterkado.nl/",
    },
  ];

  return <Logos title={language.gifts} logos={logos} border />;
}

export default GiftLogos;
