import React, { useContext } from "react";
import ANWBLogo from "../../assets/images/integrations/distributions/anwb.svg";
import BlackhawkLogo from "../../assets/images/integrations/distributions/blackhawk.svg";
import ConwayLogo from "../../assets/images/integrations/distributions/conway.svg";
import DappreLogo from "../../assets/images/integrations/distributions/dappre.svg";
import EpayLogo from "../../assets/images/integrations/distributions/epay.svg";
import GivaCardLogo from "../../assets/images/integrations/distributions/givacard.svg";
import GiftcardsGroupLogo from "../../assets/images/integrations/distributions/giftcards-group.svg";
import LekkerlandLogo from "../../assets/images/integrations/distributions/lekkerland.svg";
import PrimeraLogo from "../../assets/images/integrations/distributions/primera.svg";
import { LanguageContext } from "../../contexts/Language";
import { importFiles } from "../../utils/utils";
import Logos from "../molecules/Logos";

function DistributionsLogos() {
  const [language] = useContext(LanguageContext);
  const images = importFiles(require.context("../../assets/images/integrations/distributions/", false, /\.(webp|gif|png|jpe?g|png)$/));

  const logos = [
    {
      alt: "ANWB",
      src: ANWBLogo,
      href: "https://www.anwb.nl/",
    },
    {
      alt: "Blackhawk",
      src: BlackhawkLogo,
      href: "https://blackhawknetwork.com/",
    },
    {
      alt: "Primera",
      src: PrimeraLogo,
      href: "https://www.primera.nl/",
    },
    {
      alt: "Lekkerland",
      src: LekkerlandLogo,
      href: "https://www.lekkerland.nl/",
    },
    {
      alt: "Conway",
      src: ConwayLogo,
      href: "https://www.conway.be/",
    },
    {
      alt: "epay",
      src: EpayLogo,
      href: "https://epay.de/",
    },
    {
      alt: "ICP",
      src: images["icp.jpg"],
      href: "https://www.icpcompanies.nl/",
    },
    {
      alt: "Dappre",
      src: DappreLogo,
      href: "https://dappre.com/",
    },
    {
      alt: "GivaCard",
      src: GivaCardLogo,
      href: "https://www.givacard.nl/",
    },
    {
      alt: "giftcards.group",
      src: GiftcardsGroupLogo,
      href: "https://www.giftcardsgroup.com/",
    },
    {
      alt: "Tixway",
      src: images["tixway.png"],
    },
    {
      alt: "H@nd",
      src: images["hand.png"],
      href: "https://www.linkedin.com/company/h-nd-nl",
    },
  ];

  return <Logos title={language.distribution} logos={logos} border />;
}

export default DistributionsLogos;
