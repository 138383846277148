import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/Language";
import { importFiles } from "../../utils/utils";
import Logos from "../molecules/Logos";

function CashRegisterLogos() {
  const [language] = useContext(LanguageContext);
  const images = importFiles(require.context("../../assets/images/integrations/cash-registers/", false, /\.(webp|gif|png|jpe?g|svg)$/));

  const logos = [
    {
      alt: "NCR",
      src: images["ncr.svg"],
      href: "https://www.ncr.com/",
    },
    {
      alt: "Eijsink",
      src: images["eijsink.svg"],
      href: "https://www.eijsink.nl/",
    },
    {
      alt: "Fujitsu",
      src: images["fujitsu.png"],
      href: "https://www.fujitsu.com/global/",
    },
    {
      alt: "Cow Hills",
      src: images["cow-hills.svg"],
      href: "https://www.cowhills.com/",
    },
    {
      alt: "CTAC",
      src: images["ctac.webp"],
      href: "https://www.ctac.nl/",
    },
    {
      alt: "Cocosoft",
      src: images["cocosoft.png"],
      href: "https://www.cocosoft.nl/",
    },
    {
      alt: "Octopos",
      src: images["octopos.svg"],
      href: "https://www.octopos.nl/",
    },
    {
      alt: "Digi Access",
      src: images["digi-access.png"],
      href: "http://www.digiaccess.nl/",
    },
    {
      alt: "easyPos",
      src: images["easypos.png"],
      href: "https://www.easypos.nl/",
    },
    {
      alt: "De Haan IT",
      src: images["de-haan-it.png"],
      href: "https://www.dehaanit.com/",
    },
    {
      alt: "K3",
      src: images["k3.svg"],
      href: "https://www.k3btg.com/",
    },
    {
      alt: "Microcash",
      src: images["microcash.png"],
      href: "https://microcash.nl/",
    },
    {
      alt: "retailvista",
      src: images["retailvista.png"],
      href: "https://www.retailvista.nl/",
    },
    {
      alt: "Retailstars",
      src: images["retailstars.jpg"],
      href: "https://retailstars.nl/",
    },
    {
      alt: "SRS",
      src: images["srs.svg"],
      href: "https://srs.nl/",
    },
    {
      alt: "Sofindev",
      src: images["sofindev.svg"],
      href: "https://www.sofindev.com/nl",
    },
    {
      alt: "Valk solutions",
      src: images["valk-solutions.png"],
      href: "https://valksolutions.nl/",
    },
    {
      alt: "Vendit",
      src: images["vendit.png"],
      href: "https://www.vendit.nl/",
    },
    {
      alt: "ACA",
      src: images["aca.png"],
      href: "https://www.aca.nl/",
    },
    {
      alt: "AXI",
      src: images["axi.svg"],
      href: "https://www.axi.nl/nl",
    },
    {
      alt: "BOVERTIS",
      src: images["bovertis.svg"],
      href: "https://www.bovertis.com/",
    },
    {
      alt: "MplusKassa",
      src: images["mplus.svg"],
      href: "https://www.mpluskassa.nl/",
    },
    {
      alt: "Bork",
      src: images["bork-horeca.png"],
      href: "https://www.bork.nl/",
    },
    {
      alt: "Hunter CRM",
      src: images["hunter-crm.png"],
      href: "https://www.hunter-crm.com/",
    },
  ];

  return <Logos title={language.cashRegister} logos={logos} border />;
}

export default CashRegisterLogos;
